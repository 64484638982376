import { Link } from "gatsby"
import React, { Component } from "react"
import Layout from "../components/Layout"

// export default function regionrequester() {

class regionrequester extends Component {
  constructor(props) {
    super(props)

    this.state = {
      msg: "start",
      files: 1,
      x1: 0,
      x2: 0,
      z1: 0,
      z2: 0,

      requesting: false,
      requestsuccess: false,
      requestmsg: "",
      dlurl: "",
    }
    this.change = this.handlechange.bind(this)
    this.request = this.handlerequest.bind(this)
  }

  handlechange(value) {
    var xr = Math.floor(this.state.x1 / 512)
    var x2r = Math.floor(this.state.x2 / 512)
    var zr = Math.floor(this.state.z1 / 512)
    var z2r = Math.floor(this.state.z2 / 512)
    if (value.target.name === "x1") {
      this.setState({ x1: value.target.value }, () => {
        Math.floor(value.target.value / 512)
      })
    } else if (value.target.name === "x2") {
      this.setState({ x2: value.target.value })
    } else if (value.target.name === "z1") {
      this.setState({ z1: value.target.value })
    } else if (value.target.name === "z2") {
      this.setState({ z2: value.target.value })
    }

    var xrd = xr - x2r
    var zrd = zr - z2r

    var files = (xrd === 0 ? 1 : xrd) * (zrd === 0 ? 1 : zrd)

    if (files < 0) {
      files *= -1
    }
    this.setState({ files: files })
  }

  handlerequest() {
    this.setState({
      requesting: true,
      requestsuccess: false,
      requestmsg: "requesting",
      dlurl: "",
    })
    if (this.state.files > 100) {
      this.setState({ requestmsg: "More than 100 files are not allowed!" })
      return
    }
    fetch(
      `https://salcapi.n00bs.info/query/${this.state.x1}/${this.state.z1}/${this.state.x2}/${this.state.z2}/`
    )
      .then(response => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 403) {
          throw new Error("More than 100 files are not allowed!")
        }
        throw new Error("Unknown error: " + String(response.status))
      })
      .then(json => {
        this.setState({
          requestmsg: json.msg,
          dlurl: json.dlurl,
          requesting: false,
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          requesting: false,
          requestsuccess: false,
          requestmsg: error.message,
        })
      })
  }

  render() {
    return (
      <Layout>
        <div className="text-center">
          <h1 className="text-4xl">Region Requester</h1>

          <p>
            x region: {Math.floor(this.state.x1 / 512)} -{" "}
            {Math.floor(this.state.x2 / 512)}
          </p>
          <p>
            z region: {Math.floor(this.state.z1 / 512)} -{" "}
            {Math.floor(this.state.z2 / 512)}
          </p>
          <p>files: {this.state.files}</p>
          <p>
            {this.state.files > 100
              ? "filelimit: 100 WARNING FILE LIMIT EXEEDED!"
              : "filelimit: 100"}
          </p>
          <p>{this.state.requestmsg}</p>
          <a className="underline" href={this.state.dlurl}>
            {this.state.dlurl}
          </a>

          <br />
          <p>Cordinate 1</p>
          <p className="text-black" >
            X1:{" "}
            <input
              name="x1"
              type="number"
              defaultValue="0"
              onInput={this.change}
            />{" "}
            Z1:{" "}
            <input
              name="z1"
              type="number"
              defaultValue="0"
              onInput={this.change}
            />{" "}
          </p>
          <br />
          <p>Cordinate 2</p>
          <p className="text-black">
            X2:{" "}
            <input
              name="x2"
              type="number"
              defaultValue="0"
              onInput={this.change}
            />{" "}
            Z2:{" "}
            <input
              name="z2"
              type="number"
              defaultValue="0"
              onInput={this.change}
            />
          </p>
          <br />
          <p>
            <input
              className="text-black py-2 px-10 bg-white"
              type="submit"
              value="Request Region"
              onClick={this.request}
            />
          </p>
          <Link className="hover:underline" to="/downloads">map-data and player-data can be found in the download section</Link>
        </div>
      </Layout>
    )
  }
}

export default regionrequester
